@mixin darkMode {
    @if ($darkMode) {
        @media (prefers-color-scheme: dark) {
            @content;
        }
    }
}

.dialog-scrollable {
    .p-dialog-content {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        & > :first-child {
            height: 100%;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }
}

.p-checkbox.p-checkbox-disabled:not(.p-checkbox-checked) {
    .p-checkbox-box {
        background: var(--surface-100);
    }
}

// TODO: Replace with tailwind class when available

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes pulse {
    50% {
        opacity: 0.5;
    }
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.max-w-1200px {
    max-width: 1200px;
}

.min-height-400px {
    min-height: 400px;
}

.break-words {
    word-break: break-word;
}
