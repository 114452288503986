@import './colors.scss';

.p-chip {
    height: 1.5rem;
}

@mixin bold-chip {
    font-weight: bold;
}

@mixin x-small-chip {
    font-size: 70% !important;
}

@mixin small-chip {
    font-size: 80% !important;
}

@mixin medium-chip {
    font-size: 90% !important;
}

@mixin normal-chip {
    font-size: 100% !important;
}

@mixin large-chip {
    font-size: 120% !important;
}

@mixin xxl-chip {
    font-size: 180% !important;
}

@mixin green-chip {
    color: var(--green-900);
    background-color: var(--green-300);
}

@mixin blue-chip {
    color: var(--blue-900);
    background-color: var(--blue-300);
}

@mixin yellow-chip {
    color: var(--yellow-900);
    background-color: var(--yellow-300);
}

@mixin orange-chip {
    color: var(--orange-900);
    background-color: var(--orange-300);
}

@mixin red-chip {
    color: rgb(111, 0, 0);
    @include red-400();
}

@mixin gray-chip {
    color: var(--gray-900);
    background-color: var(--bluegray-300);
}

@mixin dark-gray-chip {
    color: black;
    background-color: var(--bluegray-500);
}

.p-chip {
    @include small-chip();
}

.p-chip-medium {
    @include medium-chip();
}

.p-chip-normal {
    @include normal-chip();
}

.p-chip-large {
    @include large-chip();
}

.p-chip.status-onrisk {
    @include bold-chip();
    @include green-chip();
}

.p-chip.status-active {
    @include bold-chip();
    @include green-chip();
}

.p-chip.status-inactive {
    @include bold-chip();
    @include red-chip();
}

.p-chip.status-suspended {
    @include bold-chip();
    @include gray-chip();
}

.p-chip.status-quote {
    @include bold-chip();
    @include dark-gray-chip();
}

.p-chip.status-canceledbycustomer {
    @include bold-chip();
    @include red-chip();
}
.p-chip.status-canceledbyoperator {
    @include bold-chip();
    @include red-chip();
}
.p-chip.status-canceledbyinsurer {
    @include bold-chip();
    @include red-chip();
}

.p-chip.main-insured-person {
    @include bold-chip();
    @include dark-gray-chip();
}

.p-chip.additional-insured-person {
    @include gray-chip();
}
