@mixin red-100 {
    background-color: rgb(255, 223, 223);
}
@mixin red-200 {
    background-color: rgb(255, 189, 189);
}
@mixin red-300 {
    background-color: rgb(255, 166, 166);
}
@mixin red-400 {
    background-color: rgb(249, 122, 122);
}
@mixin red-500 {
    background-color: rgb(247, 97, 97);
}
@mixin red-600 {
    background-color: rgb(236, 70, 70);
}
@mixin red-700 {
    background-color: rgb(228, 41, 41);
}
@mixin red-800 {
    background-color: rgb(222, 29, 29);
}
@mixin red-900 {
    background-color: rgb(202, 0, 0);
}

@mixin text-red-100 {
    color: rgb(255, 223, 223);
}
@mixin text-red-200 {
    color: rgb(255, 189, 189);
}
@mixin text-red-300 {
    color: rgb(255, 166, 166);
}
@mixin text-red-400 {
    color: rgb(255, 133, 133);
}
@mixin text-red-500 {
    color: rgb(255, 106, 106);
}
@mixin text-red-600 {
    color: rgb(255, 82, 82);
}
@mixin text-red-700 {
    color: rgb(255, 44, 44);
}
@mixin text-red-800 {
    color: rgb(255, 31, 31);
}
@mixin text-red-900 {
    color: rgb(255, 0, 0);
}

.bg-red-100 {
    @include red-100();
}

.bg-red-200 {
    @include red-200();
}

.bg-red-300 {
    @include red-300();
}

.bg-red-400 {
    @include red-400();
}

.bg-red-500 {
    @include red-500();
}

.bg-red-600 {
    @include red-600();
}

.bg-red-700 {
    @include red-700();
}

.bg-red-800 {
    @include red-800();
}

.bg-red-900 {
    @include red-900();
}

.text-red-100 {
    @include text-red-100();
}

.text-red-200 {
    @include text-red-200();
}

.text-red-300 {
    @include text-red-300();
}

.text-red-400 {
    @include text-red-400();
}

.text-red-500 {
    @include text-red-500();
}

.text-red-600 {
    @include text-red-600();
}

.text-red-700 {
    @include text-red-700();
}

.text-red-800 {
    @include text-red-800();
}

.text-red-900 {
    @include text-red-900();
}
