@import './chips.scss';
@import './colors.scss';

@import 'primeng/resources/primeng.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

@import 'flag-icons/css/flag-icons.min.css';

* {
    font-family: 'roboto-regular';
}

::ng-deep {
    html {
        background-color: var(--surface-ground);
        overflow-y: overlay;
        color: black;
    }

    .content {
        position: absolute;
        top: 55px;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 12px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--surface-400);
        border-radius: 4px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--surface-500);
    }

    .invalid-input {
        border: 1px solid red !important;
    }

    .p-message {
        width: 200px;
    }

    .p-menuitem-link-active {
        font-weight: bold !important;
    }

    .custom-link {
        text-decoration: none;
        color: var(--surface-900);

        * {
            color: var(--blue-400);
        }
    }

    .custom-link:hover {
        color: var(--surface-700);
        text-decoration: underline;

        * {
            color: var(--blue-300);
        }
    }

    .fill-height {
        min-height: 100% !important;
        height: auto;
    }

    .p-dialog-content {
        margin: 0px !important;
        padding: 0px !important;
    }

    .p-dialog {
        border: none;
    }

    .p-confirm-dialog .p-dialog-content {
        padding: 1rem !important;
    }

    .p-chip.open-chip {
        @include red-600();
        color: white;
    }

    .p-chip.resolved-chip {
        background: var(--bluegray-500);
        color: white;
    }

    .blurred-layer {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        z-index: 10;
    }

    #table-head {
        position: sticky;
        top: -2px;
        z-index: 1;

        th {
            background-color: var(--surface-ground);
        }
    }

    .table-head-loading {
        opacity: 0.5;
    }

    .p-datatable .p-datatable-loading-overlay {
        z-index: 1 !important;
    }
}
